// Cookie Consent scripting
import 'cookieconsent';

window.addEventListener("load", function(){
  window.cookieconsent.initialise({
    "palette": {
      // "popup": {
      //   "background": "#ffffff",
      //   "text": "#0085ad"
      // },
      // "button": {
      //   "background": "#0085ad",
      //   "text": "#ffffff"
      // }
    },
    "content": {
      "message": "This website uses cookies to improve your experience. By using or registering on any portion of this site, you agree to our privacy policy.",
      "dismiss": "Accept",
      "link": "Read More",
      "href": "https://www.soteria.com/privacy-policy/"
    }
  })
});
