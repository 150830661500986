import '../../../styles/src/05-components/navigation/style.scss';

document.addEventListener(
    "DOMContentLoaded", () => {
        // Show and hide navigation on mobile when you click on hamburger menu
        const navigationToggle = document.querySelector('#navigation-menu-toggle');
        const navigationMenu = document.querySelector('.navigation__menu');
        const navigationOverlay = document.querySelector('.navigation__overlay');

        let myTimeout;

        function showMenu() {
            navigationToggle.children[0].classList.add('top-bar');
            navigationToggle.children[1].classList.add('middle-bar');
            navigationToggle.children[2].classList.add('bottom-bar');
            navigationMenu.style.right = '0';
            navigationOverlay.style.display = 'block';
            myTimeout = window.setTimeout(() => {
                navigationOverlay.style.opacity = '1';
            }, 1);
        }

        function hideMenu() {
            navigationToggle.children[0].classList.remove('top-bar');
            navigationToggle.children[1].classList.remove('middle-bar');
            navigationToggle.children[2].classList.remove('bottom-bar');
            navigationMenu.style.right = '-305px';
            navigationOverlay.style.opacity = '0';
            myTimeout = window.setTimeout(() => {
                navigationOverlay.style.display = 'none';
            }, 500);
        }

        function toggleMenu() {
            clearTimeout(myTimeout);
            if (document.querySelector('.top-bar')) {
                hideMenu();
            } else {
                showMenu();
            }
        }

        navigationToggle.addEventListener('click', function() {
            toggleMenu();
        });

        navigationOverlay.addEventListener('click', function() {
            toggleMenu();
        });

        // Add effect when scrolled from top
        const myNav = document.querySelector('.navigation');
        let windowPosition = window.scrollY;
        let myNavHeight = myNav.offsetHeight;

        function navScrolled(windowPosition, myNavHeight) {
            if (windowPosition > myNavHeight) {
                myNav.classList.add('navigation--scrolled');
            } else {
                myNav.classList.remove('navigation--scrolled');
            }
        }
        navScrolled(windowPosition, myNavHeight);

        window.addEventListener('scroll', function() {
            windowPosition = window.scrollY;

            navScrolled(windowPosition, myNavHeight);
        });
    }
);